import _styled from "styled-components";
import { Button, ButtonType, Modal, Typography, TypographyAlignment, TypographySize, TypographyWeight } from '~/components/core';
const ConfirmationModalContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "1.5rem"
});
const HeaderAndSubtextContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "justifyContent": "center",
  "gap": "0.5rem"
});
const ButtonsContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "flex-end",
  "gap": "0.5rem"
});
interface ConfirmationModalProps {
  header: string;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  cancelButtonLabel?: string;
  children?: React.ReactNode;
  confirmButtonLabel?: string;
  confirmLoading?: boolean;
  subtext?: string;
}
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  header,
  subtext,
  confirmButtonLabel,
  cancelButtonLabel,
  confirmLoading = false,
  onConfirm,
  onCancel,
  children
}) => <Modal open={open}>
    <ConfirmationModalContainer>
      <HeaderAndSubtextContainer>
        <Typography alignment={TypographyAlignment.CENTER} size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
          {header}
        </Typography>
        {subtext && <Typography alignment={TypographyAlignment.CENTER}>
            {subtext}
          </Typography>}
      </HeaderAndSubtextContainer>
      {children}
      <ButtonsContainer>
        <Button label={cancelButtonLabel ?? 'Cancel'} onClick={onCancel} type={ButtonType.OUTLINED} />
        <Button label={confirmButtonLabel ?? 'Confirm'} loading={confirmLoading} onClick={onConfirm} type={ButtonType.FILLED} />
      </ButtonsContainer>
    </ConfirmationModalContainer>
  </Modal>;
export default ConfirmationModal;