import _styled from "styled-components";
import { Color, Icon, Icons, Typography, TypographySize } from '../core';
const StyledDropdownItem = _styled.div<{
  $disabled?: boolean;
}>(({
  $disabled
}) => [{
  "display": "flex",
  "alignItems": "center",
  "gap": "0.5rem",
  "borderRadius": "0.125rem",
  "paddingLeft": "0.375rem",
  "paddingRight": "0.375rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem"
}, $disabled ? {
  "cursor": "not-allowed",
  "opacity": "0.5"
} : {
  "cursor": "pointer",
  "&:hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(220 252 231 / var(--tw-bg-opacity))"
  }
}]);
export type DropdownItemProps = {
  onClick: () => void;
  title: string;
  destructive?: boolean | undefined;
  disabled?: boolean;
  icon?: Icon | undefined;
};
export const DropdownItem: React.FC<DropdownItemProps> = ({
  icon,
  title,
  onClick,
  destructive = false,
  disabled = false
}: DropdownItemProps) => {
  const iconAndTextColor = destructive ? Color.RED : Color.NAVY;
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!disabled) {
      onClick();
    }
  };
  return <StyledDropdownItem $disabled={disabled} onClick={handleClick}>
      {icon && <Icons color={iconAndTextColor} icon={icon} />}
      <Typography color={iconAndTextColor} size={TypographySize.SMALL}>
        {title}
      </Typography>
    </StyledDropdownItem>;
};
export default DropdownItem;