import { RequireOnlyOne } from '@kindo/universal/src/utils/type.utils';
import React, { useState } from 'react';
import { Button, ButtonType, Color, Icon, Size } from '~/components/core';
type BaseCopyButtonProps = {
  buttonType?: ButtonType;
  'data-id'?: string | undefined;
  disabled?: boolean;
  getText?: () => string;
  icon?: Icon;
  label?: string;
  showSuccessTime?: number;
  size?: Size;
  successText?: string;
  text?: string;
  textColor?: Color | undefined;
  tooltip?: string;
};
type CopyButtonProps = RequireOnlyOne<BaseCopyButtonProps, 'text' | 'getText'>;
const CopyButton: React.FC<CopyButtonProps> = ({
  text,
  'data-id': dataId,
  label = 'Copy',
  icon = Icon.COPY,
  tooltip,
  size = Size.MEDIUM,
  textColor,
  showSuccessTime = 1000,
  successText = 'Copied',
  buttonType = ButtonType.OUTLINED,
  disabled = false,
  getText = () => text
}) => {
  const [copied, setCopied] = useState(false);
  const copyText = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const textToCopy = text || getText();
    if (textToCopy) {
      await navigator.clipboard.writeText(textToCopy);
    }
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, showSuccessTime);
  };
  return <Button data-id={dataId} disabled={disabled} label={copied ? successText : label} onClick={copyText} size={size} startIcon={copied ? Icon.CHECK : icon} textColor={textColor} tooltip={tooltip} type={buttonType} />;
};
export default CopyButton;