import _styled from "styled-components";
import { OrgPaymentTier, ORG_PAYMENT_TIER_DISPLAY_NAMES, MANAGE_SUBSCRIPTION_FULL_ROUTE } from '@kindo/universal';
import { signOut, useSession } from 'next-auth/react';
import { Button, ButtonType, Color, Icon, Icons, Size, Typography, TypographySize, TypographyWeight } from '~/components/core';
import { Dropdown } from '~/components/Dropdown';
import { LOGIN_ROUTE } from '~/constants';
import { useAppSelector } from '~/hooks';
import useGetCredits from '~/hooks/useGetOrgCredits';
const ProfileIconTypographyContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center"
});
const ProfileDropdownContainer = _styled.div({
  "display": "flex",
  "width": "max-content",
  "flexDirection": "column",
  "gap": "0.25rem"
});
// Note: ProfileIconContainer style is from IconButtonContainer in IconButton.tsx
const ProfileIconContainer = _styled.div({
  "display": "flex",
  "height": "fit-content",
  "width": "fit-content",
  "flexShrink": "0",
  "alignItems": "center",
  "justifyContent": "center",
  "borderRadius": "9999px",
  "padding": "9px",
  "&:hover": {
    "--tw-bg-opacity": "0.2",
    "backgroundColor": "rgb(128 237 153 / var(--tw-bg-opacity))"
  }
});
// Note: Padding aligns these with the padding from the sign out button
const UserInfoAndAccountInfoContainer = _styled.div({
  "paddingLeft": "0.75rem",
  "paddingRight": "0.75rem"
});
const UserInfoContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "borderBottomWidth": "0.063rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem"
});
const TierAndUpgradeContainer = _styled.div({
  "display": "flex",
  "gap": "0.5rem"
});
const TierContainer = _styled.div({
  "display": "flex",
  "gap": "0.25rem"
});
const AccountInfoDetailsContainer = _styled.div({
  "display": "flex",
  "gap": "0.25rem"
});
const AccountInfoContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.25rem",
  "borderBottomWidth": "0.063rem",
  "paddingTop": "0.25rem",
  "paddingBottom": "0.25rem"
});
const StyledDropdown = _styled(Dropdown)`
  ${{
  "padding": "0px",
  "&:hover": {
    "backgroundColor": "transparent"
  },
  "&[aria-expanded=\"true\"]": {
    "backgroundColor": "transparent"
  }
}}
`;
const ProfileButton: React.FC = () => {
  const {
    data: session
  } = useSession();
  const user = session?.user;
  const userName = user?.name;
  const userEmail = user?.email;
  const {
    orgCredits
  } = useGetCredits();

  // Redux
  const {
    org
  } = useAppSelector(state => state.user);
  const activeTier = org?.paymentTier || OrgPaymentTier.FREE;
  const isEnterprise = activeTier === OrgPaymentTier.ENTERPRISE;
  return <StyledDropdown trigger={<ProfileIconTypographyContainer>
          <ProfileIconContainer>
            <Icons color={Color.NAVY} icon={Icon.PROFILE} size={Size.LARGE} />
          </ProfileIconContainer>

          <Typography color={Color.NAVY} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
            Profile
          </Typography>
        </ProfileIconTypographyContainer>}>
      <ProfileDropdownContainer>
        {/* User Name + Email */}
        <UserInfoAndAccountInfoContainer>
          <UserInfoContainer>
            <Typography color={Color.NAVY} size={TypographySize.MEDIUM} weight={TypographyWeight.MEDIUM}>
              {userName || userEmail}
            </Typography>
            {userName && <Typography color={Color.GRAY} size={TypographySize.SMALL} weight={TypographyWeight.LIGHT}>
                {userEmail}
              </Typography>}
          </UserInfoContainer>
          {/* Payment Tier + Credits Remaining */}
          <AccountInfoContainer>
            <TierAndUpgradeContainer>
              <TierContainer>
                <Typography color={Color.NAVY} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                  Current plan:
                </Typography>
                <Typography color={Color.NAVY} size={TypographySize.SMALL} weight={TypographyWeight.BOLD}>
                  {ORG_PAYMENT_TIER_DISPLAY_NAMES[activeTier]}
                </Typography>
              </TierContainer>
              {!isEnterprise && <Button href={MANAGE_SUBSCRIPTION_FULL_ROUTE} label="Upgrade" size={Size.X_SMALL} textColor={Color.NAVY} type={ButtonType.OUTLINED} />}
            </TierAndUpgradeContainer>
            {orgCredits !== undefined && <AccountInfoDetailsContainer>
                <Typography color={Color.NAVY} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
                  Credits Remaining:
                </Typography>
                <Typography color={Color.NAVY} size={TypographySize.SMALL} weight={TypographyWeight.BOLD}>
                  {/* Show commas */}
                  {orgCredits.toLocaleString()}
                </Typography>
              </AccountInfoDetailsContainer>}
          </AccountInfoContainer>
        </UserInfoAndAccountInfoContainer>
        {/* Sign Out */}
        <Button label="Sign Out" onClick={() => signOut({
        callbackUrl: LOGIN_ROUTE
      })} startIcon={Icon.LOGOUT} type={ButtonType.TEXT} />
      </ProfileDropdownContainer>
    </StyledDropdown>;
};
export default ProfileButton;