import _styled from "styled-components";
import { ContentSource } from '../../Library/components/FilesTab';
import { ContentIcon } from '../ContentIcon';
import { Color, Icon, Icons, Size, ToolTip, Typography, TypographySize } from '~/components/core';
import { withLink } from '~/components/core/hocs';
import { TypographyWrap } from '~/components/core/Typography';
import { getContentDirectFetchUrl } from '~/constants';
const ContentReferenceContainer = _styled.div<{
  $isClickable: boolean;
}>`
  ${({
  $isClickable
}) => [{
  "display": "flex",
  "minWidth": "fit-content",
  "alignItems": "center",
  "gap": "0.25rem",
  "whiteSpace": "nowrap",
  "borderRadius": "9999px",
  "borderWidth": "0.063rem",
  "borderColor": "#095A7C58",
  "paddingTop": "0.375rem",
  "paddingBottom": "0.375rem",
  "paddingLeft": "0.625rem",
  "paddingRight": "0.75rem"
}, $isClickable && {
  "cursor": "pointer",
  "&:hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(9 90 124 / var(--tw-border-opacity))"
  }
}]}
`;
interface ContentReferenceProps {
  contentId: string;
  fileName: string;
  source: ContentSource;
  canDownload?: boolean;
  nameMaxWidth?: number | TwStyle | undefined;
  onClick?: () => void;
  showRemoveIcon?: boolean;
  tooltip?: string | undefined;
  url?: string;
}
const ContentReference = ({
  contentId,
  fileName,
  source,
  canDownload = false,
  url,
  nameMaxWidth,
  onClick,
  showRemoveIcon,
  tooltip
}: ContentReferenceProps) => <ToolTip content={tooltip}>
    {withLink(
  // TODO: Dedupe with Button, need a way to handle non-clickable ones though
  <ContentReferenceContainer $isClickable={!!onClick || canDownload || !!url} onClick={onClick}>
        <ContentIcon contentSource={source} size={Size.MEDIUM} />
        {/* Not all file names are encoded, but decode just in case */}
        <Typography color={Color.NAVY} maxWidth={nameMaxWidth} size={TypographySize.SMALL} wrap={nameMaxWidth ? TypographyWrap.NO_WRAP : TypographyWrap.NORMAL}>
          {decodeURIComponent(fileName)}
        </Typography>
        {showRemoveIcon && <span>
            <Icons color={Color.NAVY} icon={Icon.CLOSE} size={Size.SMALL} />
          </span>}
      </ContentReferenceContainer>, {
    href: canDownload ? getContentDirectFetchUrl({
      contentId,
      isDownload: true,
      isPlaintext: false
    }) : url,
    disabled: !canDownload && !url,
    newTab: true
  })}
  </ToolTip>;
export default ContentReference;