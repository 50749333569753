import _styled from "styled-components";
import React, { useState } from 'react';
import { ContentData } from '../../Files.types';
import { FileCard } from './FileCard';
import { Color, Icon, IconButton, IconButtonType, LoadingSpinner, LoadingSpinnerSize, Size } from '~/components/core';
const FileCardsContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "alignItems": "center"
});
const ArrowButtonOuterContainer = _styled.div({
  "position": "relative",
  "height": "2.25rem",
  "width": "0px"
});
const CardsContainer = _styled.div({
  "display": "grid",
  "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
  "gap": "1.5rem",
  "@media (min-width: 1008px)": {
    "gridTemplateColumns": "repeat(2, minmax(0, 1fr))"
  },
  "@media (min-width: 1450px)": {
    "gridTemplateColumns": "repeat(3, minmax(0, 1fr))"
  }
});
type FileCardsProps = {
  files: ContentData[];
  isLoading?: boolean;
};
const FileCards: React.FC<FileCardsProps> = ({
  files,
  isLoading = false
}: FileCardsProps) => {
  const pageSize = 3;
  const [page, setPage] = useState(0);
  const handlePagination = (newPage: number) => () => {
    if (newPage >= 0) {
      setPage(newPage);
    }
  };
  const hasPrevious = page > 0;
  const hasNext = page < files.length / pageSize - 1;
  return <FileCardsContainer>
      {isLoading && <LoadingSpinner size={LoadingSpinnerSize.X_LARGE} />}
      {!isLoading && <>
          {hasPrevious && <ArrowButtonOuterContainer>
              <span css={{
          "position": "absolute",
          "left": "-50px"
        }}>
                <IconButton color={Color.NAVY} icon={Icon.CHEVRON_RIGHT} invert onClick={handlePagination(page - 1)} size={Size.LARGE} type={IconButtonType.FILLED} />
              </span>
            </ArrowButtonOuterContainer>}

          <CardsContainer>
            {files.slice(page * pageSize, page * pageSize + 3).map(file => <FileCard key={file.id} file={file} />)}
          </CardsContainer>

          {hasNext && <ArrowButtonOuterContainer>
              <span css={{
          "position": "absolute",
          "right": "-50px"
        }}>
                <IconButton color={Color.NAVY} icon={Icon.CHEVRON_RIGHT} onClick={handlePagination(page + 1)} size={Size.LARGE} type={IconButtonType.FILLED} />
              </span>
            </ArrowButtonOuterContainer>}
        </>}
    </FileCardsContainer>;
};
export default FileCards;