import _styled from "styled-components";
import { useRouter } from 'next/router';
import { Color, Icon, IconButton, IconButtonType, Size, Typography, TypographyAlignment, TypographySize, TypographyWeight } from '~/components/core';
import { withLink } from '~/components/core/hocs';
const NavButtonContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center"
});
interface NavButtonProps {
  icon: Icon;
  route: string;
  title: string;
  'data-id'?: string | undefined;
  hideActive?: boolean;
}
const NavButton: React.FC<NavButtonProps> = ({
  icon,
  route,
  title,
  hideActive,
  'data-id': dataId
}) => {
  const router = useRouter();
  const isActive = router.pathname.includes(route) && !hideActive;
  return withLink(<NavButtonContainer>
      <IconButton active={isActive} color={Color.NAVY} data-id={dataId} href={route} icon={icon} size={Size.LARGE} title={title} type={IconButtonType.SIMPLE} />
      <Typography alignment={TypographyAlignment.CENTER} color={Color.NAVY} size={TypographySize.SMALL} weight={TypographyWeight.MEDIUM}>
        {title}
      </Typography>
    </NavButtonContainer>, {
    href: route
  });
};
export default NavButton;