import _styled from "styled-components";
import { CustomDlpFilterType, DlpFilter, DlpFilterPolicy } from '@kindo/universal';
import { CustomDlpFilters } from './CustomDlpFilters';
import { CustomDlpFilter } from './DlpFilters.types';
import { StandardDlpFilters } from './StandardDlpFilters';
import SettingsSection from '~/components/Settings/SettingsSection';
const PrivacyFiltersContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "4rem"
});
interface DlpFiltersConfigProps {
  canAccessSecuritySettings: boolean;
  createCustomDlpFilter: (policy: DlpFilterPolicy, type: CustomDlpFilterType, value: string) => void;
  customDlpFilters: CustomDlpFilter[];
  deleteCustomDlpFilter: (value: string) => void;
  dlpFiltersConfig: Record<DlpFilter, DlpFilterPolicy> | undefined;
  dlpFiltersConfigIsLoading: boolean;
  targetResourceLabel: string;
  updateStandardDlpFilterConfig: (dlpFilter: DlpFilter, newPolicy: DlpFilterPolicy) => void;
  updatingFilters: DlpFilter[];
}
const DlpFiltersConfig: React.FC<DlpFiltersConfigProps> = ({
  canAccessSecuritySettings,
  createCustomDlpFilter,
  customDlpFilters,
  deleteCustomDlpFilter,
  dlpFiltersConfig,
  dlpFiltersConfigIsLoading,
  targetResourceLabel,
  updateStandardDlpFilterConfig,
  updatingFilters
}) => <PrivacyFiltersContainer>
    <SettingsSection description={`Control which types of information are redacted for ${targetResourceLabel}.`} title="Data Loss Prevention Filters">
      <StandardDlpFilters canAccessSecuritySettings={canAccessSecuritySettings} dlpFiltersConfig={dlpFiltersConfig} dlpFiltersConfigIsLoading={dlpFiltersConfigIsLoading} updateStandardDlpFilterConfig={updateStandardDlpFilterConfig} updatingFilters={updatingFilters} />
    </SettingsSection>
    <SettingsSection description={`Create custom phrases or regular expressions that will be redacted for ${targetResourceLabel}.`} title="Custom Filters">
      <CustomDlpFilters canAccessSecuritySettings={canAccessSecuritySettings} createCustomDlpFilter={createCustomDlpFilter} customDlpFilters={customDlpFilters} deleteCustomDlpFilter={deleteCustomDlpFilter} />
    </SettingsSection>
  </PrivacyFiltersContainer>;
export default DlpFiltersConfig;