import DocViewer, { DocRenderer, DocViewerRenderers, IDocument } from '@cyntler/react-doc-viewer';
import { FileType, getFileTypeMimeType, isImageFileType, PREVIEWABLE_FILE_TYPES } from '@kindo/universal';
import Image from 'next/image';
import React from 'react';
import { getContentProxyFetchUrl } from '~/constants';
const TXTRenderer: DocRenderer = ({
  mainState: {
    currentDocument
  }
}) => {
  const decoder = new TextDecoder();
  if (!currentDocument?.fileData) return null;
  const fileData = typeof currentDocument.fileData === 'string' ? currentDocument.fileData : decoder.decode(currentDocument.fileData as ArrayBuffer);
  const content = Buffer.from(fileData.split(',')[1] ?? fileData, 'base64').toString();
  return <div>{content}</div>;
};
TXTRenderer.fileTypes = ['txt', 'text/plain'];
TXTRenderer.weight = 1;
type FilePreviewProps = {
  fileId?: string | undefined;
  fileType?: FileType | undefined;
};
type ErrorRendererProps = {
  fileName: string;
  document?: IDocument | undefined;
};
const ErrorRenderer: React.FC<ErrorRendererProps> = ({
  document,
  fileName
}) => {
  const fileText = fileName || document?.fileType || '';
  if (fileText) {
    return <span>Cannot preview file</span>;
  }
  return <span>Cannot preview file</span>;
};
const StaticFilePreview: React.FC<FilePreviewProps> = ({
  fileType,
  fileId
}) => {
  if (!fileId || !PREVIEWABLE_FILE_TYPES.includes(fileType as FileType)) return <span>Cannot preview file</span>;
  const fileUrl = getContentProxyFetchUrl(fileId);
  if (isImageFileType(fileType as FileType)) {
    return <Image alt="Preview" height={200} loader={() => fileUrl} src={fileUrl} width={200} />;
  }
  const document: IDocument = {
    uri: fileUrl
  };
  if (fileType) {
    document.fileType = getFileTypeMimeType(fileType);
  }
  return <div className="no-pdf-controls no-preview-scroll" css={{
    "pointerEvents": "none",
    "overflow": "hidden"
  }}>
      <style>{`
        .no-pdf-controls [id=pdf-controls] {
          display: none;
        }

        .no-preview-scroll [id=pdf-renderer] {
          overflow: hidden;
        }

        .no-preview-scroll [id=msdoc-renderer] {
          scale: 1.2;
        }

        .no-preview-scroll [id=image-renderer] {
          background: #FFF;
        }
      `}</style>
      <DocViewer config={{
      header: {
        disableHeader: true,
        disableFileName: true,
        retainURLParams: false
      },
      pdfVerticalScrollByDefault: false,
      noRenderer: {
        overrideComponent: ErrorRenderer
      },
      loadingRenderer: {
        showLoadingTimeout: false
      }
    }} documents={[document]} language="en" pluginRenderers={[...DocViewerRenderers, TXTRenderer]} style={{
      minWidth: '392px',
      height: '240px',
      border: '1px solid #EAECF0',
      borderRadius: '12px',
      padding: '10px'
    }} />
    </div>;
};
export default React.memo(StaticFilePreview);