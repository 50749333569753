import _styled from "styled-components";
import { ContentListSort } from '@kindo/api';
import { FileType, PREVIEWABLE_FILE_TYPES } from '@kindo/universal';
import { ContentTableSort } from 'components/Chat/AddFileModal/AddFileModal.consts';
import debounce from 'debounce';
import { useState } from 'react';
import { Color, Icon, Icons, Table, Typography, TypographySize } from '../../../core';
import { ContentIcon } from '../../../shared/ContentIcon';
import { ContentFilter, ContentFiltersValues, FiltersProps } from '../../../shared/Filters';
import FileCards from './components/FileCards/FileCards';
import { ContentData, ContentSource } from './Files.types';
import { Dropdown, DropdownItem } from '~/components/Dropdown';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { ToastType, useFileDropdownItems, useToast } from '~/hooks';
import { nextTrpc, trpc } from '~/trpc';
export function mapToContentData(content: Awaited<ReturnType<typeof trpc.content.list.query>>['items'][number]): ContentData {
  return {
    id: content.id,
    name: content.filename,
    fileType: content.fileType,
    uploadedBy: content.createdByName,
    createdAt: content.createdAt,
    source: content.integrationName as ContentSource
  };
}
// TODO: Create more generic library screen component, rather than just sharing styling
export const LibraryScreenContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "4rem",
  "paddingBottom": "4rem",
  "paddingRight": "4rem"
});
export const LibrarySectionWithHeader = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "1.5rem"
});
export const LibraryHeader: React.FC<{
  children: string;
}> = ({
  children
}) => <Typography color={Color.NAVY} size={TypographySize.H3}>
    {children}
  </Typography>;
export type ContentRemoveArgs = {
  contentId: string;
  fileName: string;
};
const Files: React.FC = () => {
  // State
  const [allContentSort, setAllContentSort] = useState<ContentTableSort>({
    key: ContentListSort.CREATED_AT,
    direction: 'desc'
  });
  const [contentToRemove, setContentToRemove] = useState<ContentRemoveArgs | null>(null);
  const [activeTableFilters, setActiveTableFilters] = useState<ContentFiltersValues>({
    [ContentFilter.NAME]: '',
    // search value
    [ContentFilter.TYPE]: undefined,
    [ContentFilter.SOURCE]: undefined
  });
  // Custom hooks
  const {
    getFileDropdownItems
  } = useFileDropdownItems();
  const {
    enqueueToast
  } = useToast();

  // Queries
  const {
    data: recentFilesData,
    isLoading: isLoadingRecentFiles,
    refetch: refetchRecentFiles
  } = nextTrpc.content.list.useQuery({
    limit: 100,
    sort: ContentListSort.CREATED_AT,
    sortDesc: true
  }, {
    enabled: true
  });
  const {
    data: myContentData,
    isLoading: isLoadingMyContent,
    refetch: refetchMyContent
  } = nextTrpc.content.list.useQuery({
    limit: 100,
    sort: allContentSort.key,
    sortDesc: allContentSort.direction === 'desc',
    filter: {
      textSearch: activeTableFilters.name,
      integrationName: activeTableFilters.source,
      fileTypes: activeTableFilters.type ? [activeTableFilters.type as FileType] : undefined // TODO file-types
    }
  }, {
    enabled: true
  });

  // Mutations
  const removeContentMutation = nextTrpc.content.remove.useMutation({
    onSuccess: () => {
      setContentToRemove(null);
      refetchMyContent();
      refetchRecentFiles();
      enqueueToast({
        message: 'File removed successfully',
        type: ToastType.SUCCESS
      });
    },
    onError: err => {
      console.error(err);
      enqueueToast({
        message: 'Failed to remove file',
        type: ToastType.ERROR
      });
    }
  });
  const debouncedRefetchMyFiles = debounce(refetchMyContent, 500);
  const handleSearchChange = async (search: string) => {
    setActiveTableFilters({
      ...activeTableFilters,
      [ContentFilter.NAME]: encodeURI(search)
    });
    await debouncedRefetchMyFiles();
  };
  const handleFilterChange = async (filter: ContentFiltersValues) => {
    setActiveTableFilters(filter);
    await debouncedRefetchMyFiles();
  };
  const handleSortChange = async (sort: ContentTableSort) => {
    setAllContentSort(sort);
    await debouncedRefetchMyFiles();
  };
  const handleContentRemove = (contentId: string) => removeContentMutation.mutate({
    id: contentId
  });
  const displayedRecentFiles = recentFilesData?.items.map(mapToContentData) ?? [];
  const displayedMyContent = myContentData?.items.map(mapToContentData) ?? [];
  const filterProps: FiltersProps<ContentFilter> = {
    filters: [{
      defaultLabel: 'All',
      filterKey: ContentFilter.TYPE,
      options: Object.values(FileType).map(contentType => ({
        label: contentType,
        value: contentType
      })),
      placeholder: 'Type'
    }, {
      defaultLabel: 'All',
      filterKey: ContentFilter.SOURCE,
      options: Object.keys(ContentSource).map(source => ({
        label: source,
        value: source
      })),
      placeholder: 'Source'
    }],
    filtersValues: activeTableFilters,
    searchValue: activeTableFilters.name || '',
    setFilters: handleFilterChange,
    setSearchValue: handleSearchChange
  };
  const filesToPreview = displayedRecentFiles.filter(file => file.source === ContentSource.LIBRARY && PREVIEWABLE_FILE_TYPES.includes(file.fileType as FileType));
  return <LibraryScreenContainer css={{
    "paddingLeft": "4rem"
  }}>
      {!!filesToPreview.length && <LibrarySectionWithHeader>
          <LibraryHeader>Recently used</LibraryHeader>
          <FileCards files={filesToPreview} isLoading={isLoadingRecentFiles} />
        </LibrarySectionWithHeader>}
      <Table<string, ContentFilter> columns={[{
      title: 'Source',
      sortKey: ContentListSort.INTEGRATION
    }, {
      title: 'Name',
      sortKey: ContentListSort.FILE_NAME
    }, {
      title: 'Type',
      sortKey: ContentListSort.FILE_TYPE
    }]} filterProps={filterProps} loading={isLoadingMyContent} noRowsText="No files found" rows={displayedMyContent.map(content => {
      const dropdownItems = getFileDropdownItems({
        contentId: content.id,
        onContentRemove: () => setContentToRemove({
          contentId: content.id,
          fileName: content.name
        })
      });
      return {
        key: content.id,
        cells: [
        // eslint-disable-next-line react/jsx-key
        <ContentIcon contentSource={content.source} />, content.name, content.fileType,
        // Actions
        // eslint-disable-next-line react/jsx-key
        <Dropdown trigger={<Icons color={Color.NAVY} icon={Icon.MORE} />}>
                {dropdownItems.map(option => <DropdownItem key={option.title} destructive={option.destructive} icon={option.icon} onClick={option.onClick} title={option.title} />)}
              </Dropdown>]
      };
    })} sortProps={{
      activeSort: allContentSort,
      setActiveSort: sort => handleSortChange(sort as ContentTableSort)
    }} title="My Files" />
      {contentToRemove && <ConfirmationModal
    // Question: Should we distinguish by type of file?
    confirmButtonLabel="Remove" confirmLoading={false} header={`Are you sure you want to remove ${contentToRemove.fileName} from Kindo?`} onCancel={() => {
      setContentToRemove(null);
    }} onConfirm={() => handleContentRemove(contentToRemove.contentId)} open subtext="This action cannot be undone." />}
    </LibraryScreenContainer>;
};
export default Files;