import { isMobileOnly } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling

import { Background } from '../Background';
import { Nav } from '../Nav';
import ActiveModal from './ActiveModal';
import useAcceptOrgInvite from '~/hooks/useAcceptOrgInvite';
import useCheckClientVersionOutdated from '~/hooks/useCheckClientVersionOutdated';
interface LayoutProps {
  children: React.ReactElement;
}
const Layout = ({
  children
}: LayoutProps) => {
  // Hooks
  useCheckClientVersionOutdated();
  // Hook that monitors the URL for an org invite and accepts it
  useAcceptOrgInvite();
  return <div css={{
    "display": "flex",
    "height": "100dvh",
    "width": "100vw"
  }}>
      {!isMobileOnly && <>
          <Background />
          <Nav />
          <ToastContainer />
        </>}
      <div css={{
      "display": "flex",
      "flex": "1 1 0%",
      "flexDirection": "column",
      "overflowY": "auto"
    }}>
        {children}
        <ActiveModal />
      </div>
    </div>;
};
export default Layout;